import React from 'react'
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Text from '../../components/Text';
// import Button from '../../components/Button';

// import bannerSvg from './banner.svg'
// import trashmobile from './trash-mobile.png'
// import trashmobile2 from './trash-mobile_2.png'
import { responsive } from '../../components/ThemeProvider/theme';
import JustfontBg from './JustfontBg';
// import withLoading from '../withLoading';

const title = {
  justfont: <StaticImage layout="fullWidth" placeholder="blurred" alt="字體期末考" src="./justfont/title.png" />,
  "bonne-chance": <StaticImage layout="fullWidth" placeholder="blurred" alt="營養認知大考驗" src="./bonne-chance/start_head.png" />,
  plainlaw: <StaticImage layout="fullWidth" placeholder="blurred" alt="不正常國家的不正常憲法" src="./plainlaw/start_head.png" />,
}

const start = {
  justfont: <StaticImage layout="fullWidth" placeholder="blurred" alt="開始挑戰" src="./justfont/start.png" />,
  "bonne-chance": <StaticImage layout="fullWidth" placeholder="blurred" alt="開始挑戰" src="./bonne-chance/start_button.png" />,
  plainlaw: <StaticImage layout="fullWidth" placeholder="blurred" alt="開始挑戰" src="./plainlaw/start_button.png" />,
}

const DraggerGame = ({ location, data }) => {
  const path = /[^/]+/g.exec(location.pathname)[0]
  return (
    <Flex flexDirection="column" bg={`${data.bg}.400`} mt="0" height="100%" overflow="auto">
      <JustfontBg
        path={path}
        flex="1"
        w="full"
        px={responsive('1em', '2em')}
        as={Flex}
        flexDirection={`column`}
        justifyContent={`center`}
      >
        <Box w={`full`}>
          {data.title === "img" ? (
            title[path]
          ) : <Text fontSize={responsive('1em', '1.5em')} textAlign="center">{data.title}</Text>}
        </Box>
        {data.desc && <Text mt="0.5rem" fontSize={responsive('0.875em', '1.25em')}>{data.desc}</Text>}
        <Box textAlign="center" my="2em" px="15%" w={`full`}>
          <Link to={`${location.pathname}play/`}>
          {data.start === "img" ? (
            start[path]
          ) : (
            <Box.Inline
              px="1.5rem"
              py="0.25rem"
              borderRadius="full"
              border="2px solid"
              fontSize={responsive('1.25em', '1.5em')}
              bg={`${data.bg}.400`}
            >開始測驗
            </Box.Inline>
            )}
          </Link>
        </Box>
      </JustfontBg>
    </Flex>
  )
}

export default DraggerGame
