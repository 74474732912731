import React from 'react'

import Container from '../../components/Container'
import justfontBg from './justfont/bg-pttn.png'
import bonne_bg from './bonne-chance/start_bg.png'
import plainlaw_bg from './plainlaw/bg.png'

const background = {
  justfont: justfontBg,
  "bonne-chance": bonne_bg,
  plainlaw: plainlaw_bg,
}

const JustfontBg = ({ path, ...props }) => (
  <Container
    backgroundImage={`url(${background[path]})`}
    backgroundSize={`100% auto`}
    backgroundPosition={`50% 0%`}
    maxWidth={480}
    {...props}
  />
)

export default JustfontBg
